import {
  mapActions, mapGetters, mapMutations,
} from 'vuex';

import {
  Challenge,
  Sidebar,
  Map,
  Activity,
  UserCard,
  // eslint-disable-next-line import/named
  ClubFollowersAdmin,
  ShareModal,
  Advertisement,
  Loader,
} from '@/components';

import isServer from '@/utils/isServer';

import { yearArray } from '@/utils';

if (!isServer) {
  var fileDownload = require('js-file-download');
}

export default {
  metaInfo() {
    return {
      title: 'Club detailpage',
      meta: [
        { property: 'og:title', content: this.club.name },
        { property: 'og:site_name', content: `WWSV ${this.club.name}` },
        { property: 'og:description', content: this.club.aboutUs !== undefined ? this.$options.filters.striphtml(this.club.aboutUs) : '' },
        { property: 'og:image', content: this.club.hasPicture ? this.club.profilePicture : require('@/assets/images/generic/temporary.png') },
        { property: 'twitter:card', content: 'summary_large_image' },
        { property: 'twitter:image', content: this.club.hasPicture ? this.club.profilePicture : require('@/assets/images/generic/temporary.png') },
        { property: 'twitter:image:alt', content: `WWSV ${this.club.name}` },
        { property: 'twitter:description', content: this.club.aboutUs !== undefined ? this.$options.filters.striphtml(this.club.aboutUs) : '' },
      ],
    };
  },
  name: 'ClubDetail',
  components: {
    [Challenge.name]: Challenge,
    [Sidebar.name]: Sidebar,
    [Map.name]: Map,
    [Activity.name]: Activity,
    [UserCard.name]: UserCard,
    [ClubFollowersAdmin.name]: ClubFollowersAdmin,
    [ShareModal.name]: ShareModal,
    [Advertisement.name]: Advertisement,
    [Loader.name]: Loader,
  },
  props: {},
  data: () => ({
    showShareModal: false,
    shareModalIsActive: false,
    activetab: 1,
    sidebarInfo: undefined,
    map: {
      zoom: 14,
      clubs: [],
      center: [51.0, 3.0],
      showEvents: false,
    },
    eventPage: 1,
    pastEventPage: 1,
    Loaded: false,
    followers: {
      followerList: [],
      currentPage: 1,
      pageSize: 1000,
    },
    error: false,
    errorCover: false,
    showRightArrow: false,
    showLeftArrow: false,
    title: 'Activiteiten',
    searchPlaceholderActive: 'Zoek een lopende activiteit...',
    searchPlaceholder: 'Zoek een afgelopen activiteit...',
    searchQuery: '',
    searchQueryPast: '',
    advertisement: null,
    loadingParticipants: false,
    selectedYear: { label: new Date().getFullYear().toString() },
    yearArray: yearArray.map((year) => ({ label: year })),
    loadingFiscalCsv: false,
  }),
  computed: {
    ...mapGetters('advertisement', {
      bestMatch: 'getBestmatch',
    }),
    ...mapGetters('club', {
      club: 'getClub',
      getFollowers: 'getClubFollowers',
      clubEvents: 'getClubEvents',
      pastClubEvents: 'getPastClubEvents',
    }),
    ...mapGetters('challenge', {
      challengeList: 'getChallengeList',
    }),
    website() {
      if (this.club.website.includes('http')) return this.club.website;
      return `//${this.club.website}`;
    },
    membershipLink() {
      if (this.club.membershipLink.includes('http')) return this.club.membershipLink;
      return `//${this.club.membershipLink}`;
    },
  },
  methods: {
    ...mapActions('advertisement', {
      initialiseAdvertisementBestmatch: 'FETCH_DATA_ADVERTISEMENT_BESTMATCH',
    }),
    ...mapActions('club', {
      initialiseClub: 'FETCH_DATA_CLUB',
      fetchClubEvents: 'FETCH_DATA_CLUB_EVENTS_LIST',
      fetchPastClubEvents: 'FETCH_DATA_PAST_CLUB_EVENTS_LIST',
      fetchClubFollowers: 'FETCH_DATA_CLUB_FOLLOWERS_LIST',
      followClub: 'FOLLOW_CLUB',
      unfollowClub: 'UNFOLLOW_CLUB',
      createParticipantsCsv: 'CREATE_PARTICIPANTS_CSV',
      createFiscalCsv: 'CREATE_FISCAL_CSV',
    }),
    ...mapActions('challenge', {
      initialiseChallengeList: 'FETCH_DATA_CHALLENGE_LIST',
    }),
    ...mapMutations('club', {
      clearClubFollowersList: 'CLEAR_CLUB_FOLLOWERS_LIST',
      clearClubEventsList: 'CLEAR_CLUB_EVENTS_LIST',
      clearPastClubEventsList: 'CLEAR_PAST_CLUB_EVENTS_LIST',
    }),
    getClubFollowers() {
      this.clearClubFollowersList(); // First Clear
      const isAdmin = this.isAdmin();

      const paramsObj = {
        clubId: this.club.id,
        page: this.followers.currentPage,
        pageSize: this.followers.pageSize,
        isAdmin,
      };

      this.fetchClubFollowers(paramsObj).then(() => {
        this.followers.followerList = this.getFollowers;
      });
    },
    getClubDetails() {
      return new Promise((resolve, reject) => {
        this.initialiseClub(this.$route.params.cluburl)
          .then(() => {
            const coordinates = [this.club.lat, this.club.long];

            this.map.clubs = [];
            this.map.clubs.push(this.club);
            this.map.showEvents = false;
            this.map.center = coordinates;
            this.Loaded = true;
            resolve();
          })
          .catch((err) => {
            if (err.response.status === 404) {
              this.$router.push({ name: '404' });
            } else {
              console.log(err);
              this.$toasted.show(...err.response.data);
            }
            reject();
          });
      });
    },
    getClubEvents() {
      return new Promise((resolve, reject) => {
        this.clearClubEventsList();
        this.clearPastClubEventsList();
        const params = {
          clubId: this.club.id,
          search: this.searchQuery,
          searchPast: this.searchQueryPast,
          page: this.eventPage,
          pageSize: 6,
        };
        this.fetchClubEvents(params)
          .then(() => {
            if (this.isAdmin()) {
              this.fetchPastClubEvents(params);
            }
            resolve();
          })
          .catch((err) => {
            console.log(err);
            if (err.response.status === 404) {
              this.$router.push({ name: '404' });
            } else {
              this.$toasted.show(...err.response.data);
            }
            reject();
          });
      });
    },
    loadMoreEvents() {
      this.eventPage += 1;
      const params = {
        clubId: this.club.id,
        search: this.searchQuery,
        page: this.eventPage,
        pageSize: 6,
      };
      this.fetchClubEvents(params);
    },
    loadMorePastEvents() {
      this.pastEventPage += 1;
      const params = {
        clubId: this.club.id,
        searchPast: this.searchQueryPast,
        page: this.pastEventPage,
        pageSize: 6,
      };
      this.fetchPastClubEvents(params);
    },
    followClubAction() {
      if (this.$isLoggedIn) {
        this.followClub(this.club.id).then(() => {
          this.$toasted.show(`Je volgt nu ${this.club.name}.`, { type: 'success' });
          this.getClubDetails().then(() => {
            this.getClubFollowers();
          });
        }).catch((error) => {
          this.$toasted.show(...error.response.data);
        });
      } else {
        const redirectPath = this.$route.query.redirect;
        this.$router.push(redirectPath ? { path: '/login', query: { redirect: redirectPath } } : '/login');
      }
    },
    unfollowClubAction() {
      if (this.$isLoggedIn) {
        this.unfollowClub(this.club.id).then(() => {
          this.$toasted.show(`Je volgt niet langer ${this.club.name}.`, { type: 'success' });
          this.getClubDetails().then(() => {
            this.getClubFollowers();
          });
        }).catch((error) => {
          this.$toasted.show(...error.response.data);
        });
      }
    },
    isAdmin() {
      return !!(this.$isAdmin || this.club.currentUserIsAdmin);
    },
    createNewEvent() {
      if (this.isAdmin()) {
        this.$router.push({ name: 'event-create', params: { clubId: this.club.clubURL } });
      }
    },
    downloadClubParticipants() {
      if (this.isAdmin()) {
        const params = {
          clubId: this.club.id,
        };

        this.loadingParticipants = true;

        this.createParticipantsCsv(params).then((response) => {
          fileDownload(response.data, 'deelnemers-club.csv');
          this.loadingParticipants = false;
        });
      }
    },
    closeModal() {
      // the shareModalIsActive is catches the clickOutside event that is emitted when clicking "deel"
      if (this.shareModalIsActive) {
        this.shareModalIsActive = !this.shareModalIsActive;
      } else {
        this.showShareModal = false;
      }
    },
    handleScroll(event) {
      const containerWidth = this.$refs['scroll-container'].clientWidth;
      const { scrollWidth } = event.target;
      const maxScroll = parseInt(scrollWidth - containerWidth, 10);

      var scrollEnd = event.target.scrollLeft === maxScroll;
      var scrollBegin = event.target.scrollLeft === 0;

      if (scrollEnd) {
        this.showRightArrow = false;
      } else {
        this.showRightArrow = true;
      }

      if (scrollBegin) {
        this.showLeftArrow = false;
      } else {
        this.showLeftArrow = true;
      }
    },
    search() {
      this.eventPage = 1;
      this.pastEventPage = 1;
      this.getClubEvents();
    },
    updateYear(year) {
      if (year) {
        this.selectedYear = year;
      }
    },
    exportFiscalCsv() {
      this.loadingFiscalCsv = true;

      const params = {
        clubId: this.club.id,
        year: this.selectedYear.label,
      };

      this.createFiscalCsv(params).then((response) => {
        fileDownload(response.data, `fiscale-fiche-${params.year}.csv`);
        this.loadingFiscalCsv = false;
      });
    },
  },
  serverPrefetch() {
    return this.initialiseClub(this.$route.params.cluburl).catch((error) => {
      if (error.response.status === 404) {
        this.$router.push({ name: '404' });
      }
    });
  },
  created() {
    this.getClubDetails().then(() => {
      if (this.$isLoggedIn) {
        this.getClubFollowers();
      }
      this.getClubEvents();
      const params = {
        tags: this.club.clubTags,
      };
      this.initialiseAdvertisementBestmatch(params)
        .then(() => {
          this.advertisement = this.bestMatch;
        });
    });
    if (!this.challengeList.length) this.initialiseChallengeList();
  },
  mounted() {
    setTimeout(() => {
      const containerWidth = this.$refs['scroll-container'].clientWidth;
      const { scrollWidth } = this.$refs['scroll-container'].children[0];
      const maxScroll = parseInt(scrollWidth - containerWidth, 10);

      if (maxScroll > 10) {
        this.showRightArrow = true;
      }
    }, 0);
  },
};
